import React from "react";
import { graphql, useStaticQuery, Link } from "gatsby";
import { Fixed } from "@components/feature-list";
import CreditCardIcon from "@icons/credit-card";
import StartButton from "@components/StartButton";

const GiftCards = () => {
  const images = useStaticQuery(graphql`
    query {
      xs: file(name: { eq: "eftpos-cards-2" }) {
        sharp: childImageSharp {
          fixed(width: 400) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      sm: file(name: { eq: "eftpos-cards-2" }) {
        sharp: childImageSharp {
          fixed(width: 600) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      md: file(name: { eq: "eftpos-cards-2" }) {
        sharp: childImageSharp {
          fixed(width: 700) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      lg: file(name: { eq: "eftpos-cards-2" }) {
        sharp: childImageSharp {
          fixed(width: 800) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `);

  const sources = [
    {
      ...images.xs.sharp.fixed,
      media: `(max-width: 639px)`,
    },
    {
      ...images.sm.sharp.fixed,
      media: `(max-width: 767px)`,
    },
    {
      ...images.md.sharp.fixed,
      media: `(max-width: 1023px)`,
    },
    {
      ...images.lg.sharp.fixed,
      media: `(min-width: 1024px)`,
    },
  ];

  return (
    <Fixed description={<Description/>} image={sources} />
  );
}

const Description = () => (
  <div>
    <CreditCardIcon />
    <div className="mt-6">
      <h2>
        <span className="block text-base text-highlight font-semibold tracking-wide uppercase">
          Gifting | Loyalty
        </span>
        <span className="block text-3xl font-bold tracking-tight text-gray-900 mt-1">
          Gift Cards
        </span>
      </h2>
      <p className="mt-4 text-lg text-gray-500">
        Your brand is powerful.
        Gift cards are the best way to incentivise and reward by providing your own personalised branded card.
        Beautifully designed with your chosen artwork, WAIVPAY&rsquo;s eftpos cards increase brand awareness, cements loyalty and increases sales.
        Each card can store value from $5 to $4,999.
      </p>
      <p className="mt-4 text-lg text-gray-500">
        We offer both <Link to='/giftcards#digital-eftpos' className='text-highlight'>digital</Link> and <Link to='/giftcards#physical-eftpos' className='text-highlight'>physical</Link> gift cards for your shopping centre and brand.
        Pair with <Link to='#qube' className='text-highlight'>WAIVPAY Qube</Link> for deep insights into behaviours and traffic analytics.
      </p>
      <h3 className="mt-4 text-lg text-highlight">
        WAIVPAY is Australia&rsquo;s largest supplier of gift card programs to independent shopping centres.
      </h3>
      <Stats />
      <div className="mt-6">
        <StartButton to="/giftcards" />
      </div>
    </div>
  </div>
);

const Stat = ({ children }) => (
  <div className="bg-white overflow-hidden shadow rounded-lg">
    <div className="px-4 py-5 sm:p-6">
      <dt className="text-sm font-medium text-gray-500 truncate">
        {children[0]}
      </dt>
      <dd className="mt-1 text-3xl font-semibold text-primary">
        {children[1]}
      </dd>
    </div>
  </div>
);

const Stats = () => (
  <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
    <Stat>
      <span>Total Cards Issued</span>
      <span>4.0M+</span>
    </Stat>

    <Stat>
      <span>Total Card Value</span>
      <span>$500M</span>
    </Stat>

    <Stat>
      <span>Effective Spend</span>
      <span>97.3%</span>
    </Stat>
  </dl>
);

export default GiftCards;
